import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    valueUpdated: 'false'
};

const skillExclusionSlice = createSlice({
    name: "skillExclusionSlice",
    initialState,
    reducers: {
        subComponentDataChanged: (state, action) => {
        }
    },
});

export const { subComponentDataChanged } = skillExclusionSlice.actions;

export default skillExclusionSlice.reducer;