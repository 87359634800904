import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import Preview from "../pages/RankingParameters/Preview";
import RankingParameters from "../pages/RankingParameters/RankingParameters";
import DataDisplay from "../pages/RankingParameters/DataDisplay";
import MyJob from "../pages/MyJob/MyJob";
import PrivateRoute from "../utils/PrivateRoute";
import AccessDenied from "../pages/AccessDenied/AccessDenied";

const CompanyProfile = React.lazy(() => import("../pages/ServicerPortal/CompanyProfile"));
const RedirectHandler = React.lazy(() => import("../utils/RedirectHandler"));
const Jobs = React.lazy(() => import("../pages/ServicerPortal/Jobs/Jobs"));
const RateManagementServicer = React.lazy(() => import("../pages/ServicerPortal/RateManagement/RateManagementServicer"));
const BankDetails = React.lazy(() => import("../pages/ServicerPortal/BankDetails/BankDetails"));
const IndustryAndBrand = React.lazy(() => import("../pages/ServicerPortal/IndustryAndBrand/IndustryAndBrand"));
const ServiceLocationServicer = React.lazy(() => import("../pages/ServicerPortal/CompanyProfile/ServiceLocationServicer/ServiceLocationServicer"));
const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const AccountDetails = React.lazy(() => import("../pages/AccountDetails/AccountDetails"));
const ContactMethod = React.lazy(() => import("../pages/ContactMethod/ContactMethod"));
const ServiceCoverage = React.lazy(() => import("../pages/ServiceCoverage/ServiceCoverage"));
const ServiceTimeBand = React.lazy(() => import("../pages/ServiceTimeBand/ServiceTimeBand"));
const AdditionalStartingLocation = React.lazy(() => import("../pages/AdditionalStartingLocation/AdditionalStartingLocation"));
const Servicer = React.lazy(() => import("../pages/Servicer/Servicer"));
const SuspensionView = React.lazy(() => import("../pages/AccountDetails/components/Suspension/SuspensionView"));
const SkillInclusion = React.lazy(() => import("../pages/Skills/Skill-Inclusion/SkillInclusion"));
const Industries = React.lazy(() => import("../pages/ApplicationAdmin/Industries/Industries"));
const AvailableRateCard = React.lazy(() => import("../pages/ApplicationAdmin/RateCard/AvailableRateCard/AvailableRateCard"));
const AddNewRateCard = React.lazy(() => import("../pages/ApplicationAdmin/RateCard/AddNewRateCard/AddNewRateCard"));
const AddRateType = React.lazy(() => import("../pages/ApplicationAdmin/RateType/AddRateType/AddRateType"));
const RateManagement = React.lazy(() => import("../pages/RateManagement/RateManagement"));
const SkillExclusion = React.lazy(() => import("../pages/Skills/Skill-Exclusion/SkillExclusion"));
const UploadRanking = React.lazy(() => import("../pages/RankingParameters/UploadRanking"));
const AccountDetailsServicer = React.lazy(() => import("../pages/ServicerPortal/CompanyProfile/AccountDetails/AccountDetails"));
const Login = React.lazy(() => import("../pages/Login/Login"));
const ServicerPortal = React.lazy(() => import("../pages/ServicerPortal/ServicerPortal"));
const ServicerNavBar = React.lazy(() => import("../components/ServicerNavBar/ServicerNavBar"))
const NotFound = React.lazy(() => import("../pages/NotFound"));
const ServiceOrderDetails = React.lazy(() => import("../pages/ServiceOrderDetails/ServiceOrderDetails"));
const ServiceOrder = React.lazy(() => import("../pages/ServiceOrder/ServiceOrder"));
const List = React.lazy(() => import("../pages/DispatchSimulator/List/List"));
const DispatchSimulatorDetail = React.lazy(() => import("../pages/DispatchSimulator/Detail/Detail"));
const JobOrderDetail = React.lazy(() => import("../pages/ServicerPortal/Jobs/JobOrderDetail"));
const AddNewRateCardUI = React.lazy(() => import("../pages/ApplicationAdmin/RateCard/AddNewRateCard/AddNewRateCardUI"));

const SuspenseWrapper = ({ children }) => {
    return (
        <Suspense fallback={<Loader></Loader>}>
            {children}
        </Suspense>
    );
};

const Loader = () => {
    return (
        <CircularProgress
            size={50}
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                // marginTop: `${-50 / 2}px`,
                marginTop: '200px',
                marginLeft: `${-50 / 2}px`,
            }}
        />
    );
};

const role = localStorage.getItem('roleType')


export const routes = [
    // {
    //     path: "/",
    //     element: <SuspenseWrapper><Login /></SuspenseWrapper>,
    // },
    {
        path: "/",
        element: <SuspenseWrapper><Login /></SuspenseWrapper>,
    },
    {
        path: "/AccessDenied",
        element: <PrivateRoute acessdenied={<SuspenseWrapper><AccessDenied /></SuspenseWrapper>} ></PrivateRoute>

        // element: <SuspenseWrapper><AccessDenied /></SuspenseWrapper>
    },
    {
        path: "/auth/redirect",
        element: <SuspenseWrapper><Login /></SuspenseWrapper>,
    },
    {
        path: "/CompanyProfile",
        element: <PrivateRoute normalComponent={<SuspenseWrapper><CompanyProfile /></SuspenseWrapper>} ></PrivateRoute>

    },
    {
        path: "/Jobs",
        element: <PrivateRoute normalComponent={<SuspenseWrapper><Jobs /></SuspenseWrapper>} ></PrivateRoute>

    },
    {
        path: "/AccountDetailsServicer",
        element: <PrivateRoute normalComponent={<SuspenseWrapper><AccountDetailsServicer /></SuspenseWrapper>} ></PrivateRoute>


    },
    {
        path: "/ServiceLocationServicer",
        element: <PrivateRoute normalComponent={<SuspenseWrapper><ServiceLocationServicer /></SuspenseWrapper>} ></PrivateRoute>


    },
    {
        path: "/IndustriesServicer",
        element: <PrivateRoute normalComponent={<SuspenseWrapper><IndustryAndBrand /></SuspenseWrapper>} ></PrivateRoute>

    },
    {
        path: "/BankDetails",
        element: <PrivateRoute normalComponent={<SuspenseWrapper><BankDetails /></SuspenseWrapper>} ></PrivateRoute>


    },
    {
        path: "/ServicerPortal",
        element: <PrivateRoute normalComponent={<SuspenseWrapper><ServicerPortal /></SuspenseWrapper>} ></PrivateRoute>


    },
    {
        path: "/RateManagementServicer",
        element: <PrivateRoute normalComponent={<SuspenseWrapper><RateManagementServicer /></SuspenseWrapper>} ></PrivateRoute>


    },
    {
        path: "/Jobs",
        element: <PrivateRoute normalComponent={<SuspenseWrapper><Jobs /></SuspenseWrapper>} ></PrivateRoute>


    },
    {
        path: "OrderDetails/:id",
        element: <PrivateRoute normalComponent={<SuspenseWrapper><JobOrderDetail /></SuspenseWrapper>} ></PrivateRoute>


    },
    {
        path: "/Dashboard",
        element: <PrivateRoute adminComponent={<SuspenseWrapper><Servicer /></SuspenseWrapper>} ></PrivateRoute>

    },
    {
        path: '/NetworkManagement/AddNewServicer/AccountDetails',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><AccountDetails /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/Servicer/AccountDetails/:id',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><AccountDetails /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/AddNewServicer/ContactMethod',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><ContactMethod /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/Servicer/ContactMethod/:id',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><ContactMethod /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/AddNewServicer/ServiceCoverage',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><ServiceCoverage /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/Servicer/ServiceCoverage/:id',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><ServiceCoverage /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/AddNewServicer/AdditionalStartingLocation',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><AdditionalStartingLocation /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/Servicer/AdditionalStartingLocation/:id',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><AdditionalStartingLocation /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/AddNewServicer/ServiceTimeBands',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><ServiceTimeBand /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/Servicer/ServiceTimeBands/:id',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><ServiceTimeBand /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/Servicer',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><Servicer /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/Servicer/SkillInclusion/:id',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><SkillInclusion /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/view',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><SuspensionView /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/RateManagement',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><RateManagement /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/RateManagement/:id',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><RateManagement /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/NetworkManagement/Servicer/SkillExclusion/:id',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><SkillExclusion /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/ApplicationAdmin/Industries',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><Industries /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/ApplicationAdmin/AvailableRateCard',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><AvailableRateCard /></SuspenseWrapper>} >

        </PrivateRoute>

    },

    {
        path: '/ApplicationAdmin/AddNewRateCard',
        // element: <PrivateRoute adminComponent={<SuspenseWrapper><AddNewRateCard /></SuspenseWrapper>} >
        element: <PrivateRoute adminComponent={<SuspenseWrapper><AddNewRateCardUI /></SuspenseWrapper>} >


        </PrivateRoute>

    },
    {
        path: '/ApplicationAdmin/ViewNewRateCard/:id',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><AddNewRateCard /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/ApplicationAdmin/AddNewRateType',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><AddRateType /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/ServiceOrderDetails',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><ServiceOrderDetails /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/DispatchSimulator',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><List /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/serviceOrder',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><ServiceOrder /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/detail/:id',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><DispatchSimulatorDetail /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/RankingParameters',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><RankingParameters /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/UploadRanking',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><UploadRanking /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/DisplayRanking',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><DataDisplay /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '/ServiceProvider/MyJob/:id',
        element: <PrivateRoute adminComponent={<SuspenseWrapper><MyJob /></SuspenseWrapper>} >

        </PrivateRoute>

    },
    {
        path: '*',
        element: <PrivateRoute notfound={<SuspenseWrapper><NotFound /></SuspenseWrapper>} >

        </PrivateRoute>
        // element: <SuspenseWrapper><NotFound /></SuspenseWrapper>,
    }
]
