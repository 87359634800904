import React from 'react'
import SearchBar from '../SearchBar/SearchBar'
import UserDetails from '../UserDetails/UserDetails'
import Navbar from '../Navbar/Navbar'
import "./Header.scss"
import ServicerName from "../Header/ServicerName"
import { useParams } from 'react-router-dom'


export default function Header() {
  const { id } = useParams()
  return (
    <div className='Header-Container' style={{ position: "relative" }}>
      <div className='Header'>
        <img src='/Logo.png' alt='logo' className='logo'></img>
        <div className='Search-User'>

          {id ? <ServicerName /> : null}
          <SearchBar />
          <UserDetails />
        </div>
      </div>
    </div>
  )
}
