import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
} from "react-router-dom";
import * as React from 'react';
import Header from "./components/Header/Header";
import HeaderSidebar from "./components/Header/HeaderSidebar"
import BreadCrumb from "./utils/BreadCrumb"
import { createTheme, Grid, ThemeProvider, } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { useSelector } from "react-redux";
import { routes } from "./routes/index";
import Navbar from "./components/Navbar/Navbar";
import ServicerNavBar from "./components/ServicerNavBar/ServicerNavBar";
import Interceptor from "./components/InterceptorComponent";

const theme = createTheme({
  palette: {
    primary: {
      main: '#02A0FC', // Aqua color
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFFF', // White color for secondary
      contrastText: '#000000', // Black color for secondary text
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 1024,
      lg: 1600,
      xl: 1900,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            '&:hover': {
              '& fieldset': {
                borderColor: '#02A0FC',
                borderWidth: '2px',
              },
            },
            '&.Mui-focused': {
              '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.23)'
              },
            },

          },

        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: "#02A0FC",
          }
        },
        track: {
          opacity: 0.9,
          backgroundColor: "#AEB5BC",
          ".Mui-checked.Mui-checked + &": {
            opacity: 0.9,
            backgroundColor: "#AEB5BC"
          }
        }
      }
    },

  },
  typography: {
    fontFamily: 'Poppins'
  }
});

const Layout = () => {
  const role = localStorage.getItem('roleType');
  const orgType = localStorage.getItem('organisationType');
  const location = useLocation();
  const showHeaders = location.pathname === '/';
  const environment = process.env.REACT_APP_ENV;
  

  const Display = () => {

    return environment && environment !== 'prod';
  };
  

  return (
    <div className="App" style={{ position: "relative" }}>
      <Interceptor />
      {Display() && <div className="env">{environment}</div>}
      {
        (orgType === "opw") && (
          <>
            <Header />
            <Navbar />
            <div className="boxContainer" style={{ position: "relative" }}>
              <BreadCrumb />
              <Outlet />
            </div>
          </>
        )
      }
      {
        (orgType === "servicer") && (
          <Grid sm={12} className="boxContainerServicer" style={{ position:"absolute", flexWrap: "wrap", justifyContent: "center",width:'100%',height:"100%" }}>
            <Grid container spacing={2} style={{ display: 'flex', justifyContent: "center",height:"100%" }} >
              {
                !showHeaders && (
                  <Grid className="servicer-navbar" sm={2.2} xs={2.4} item >
                    <ServicerNavBar />
                  </Grid>
                )
              }
              <Grid sm={9.8} xs={12} style={{width:"100%"}}>
                {
                  !showHeaders && (
                    <div style={{  marginTop: "20px", boxSizing:'border-box'}}>
                      <HeaderSidebar />
                    </div>
                  )
                }
                <Grid sm={12} xs={12} sx={{ padding: "0.5rem 1.8rem",boxSizing:'border-box' }}>
                  <Outlet />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      {
        !orgType && (
          <div className="boxContainer" style={{ position: 'relative' }}>
            <Outlet />
          </div>
        )
      }
    </div>
  );
};



function App() {
  const role = useSelector((state) => {
    return state?.Login?.roleType
  })
  const routerAdmin = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: routes,
    },
  ])
  return (

    <ThemeProvider theme={theme}>
      <ToastContainer />
      <RouterProvider router={routerAdmin} />
    </ThemeProvider>
  );
}

export default App;