import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    address: {
        generalInquiry: [{
            servicerId: '',
            roleId: "",
            roleName: "",
            firstName: "",
            lastName: "",
            phone: "",
            isPhoneVerified: 0,
            isEmailVerified: 0,
            email: "",
            otherRole: "",
            category: "generalInquiry",
            phoneType: "callAndText",
            otherContactMethod: "",
            addAnotherContact: 0,
            isPrimaryContact: 1,
            isUpdated: 0,
            isCreated: 0,
            isActiveFlg: 1,
            isExisting: 0,
        }],
        dispatch: [{
            servicerId: '',
            roleId: "",
            roleName: "",
            firstName: "",
            lastName: "",
            phone: "",
            isPhoneVerified: 0,
            isEmailVerified: 0,
            email: "",
            otherRole: "",
            category: "dispatch",
            phoneType: "callAndText",
            otherContactMethod: "",
            addAnotherContact: 0,
            isPrimaryContact: 1,
            isUpdated: 0,
            isCreated: 0,
            isActiveFlg: 1,
            isExisting: 0
        }],
        claims: [{
            servicerId: "",
            roleId: "",
            roleName: "",
            firstName: "",
            lastName: "",
            phone: "",
            isPhoneVerified: 0,
            isEmailVerified: 0,
            email: "",
            otherRole: "",
            category: "claims",
            phoneType: "callAndText",
            otherContactMethod: "",
            addAnotherContact: 0,
            isPrimaryContact: 1,
            isUpdated: 0,
            isCreated: 0,
            isActiveFlg: 1,
            isExisting: 0
        }]
    },
    flag: {
        previousData: false
    }
};

const contactMethodsSlice = createSlice({
    name: "contactMethods",
    initialState,
    reducers: {
        save: (state, action) => {
            state.address = action.payload
        },
        saveInitialState: (state, action) => {
            const modifiedObject = {}
            Object.entries(action.payload).forEach(([key, value]) => {
                if (value[0]?.isExisting === 0) {
                    const newData = [...initialState.address[value[0]?.category]];
                    newData[0] = { ...newData[0], isExisting: 0 }
                    modifiedObject[key] = newData;
                } else {
                    modifiedObject[key] = value;
                }
            });
            state.address = modifiedObject
        },
        savePreviousData: (state, action) => {
            state.flag.previousData = true;
        },
        reset: () => initialState
    },
});

export const { save, reset, saveInitialState, savePreviousData } = contactMethodsSlice.actions;
export const address = (state) => {
    return initialState
};

export default contactMethodsSlice.reducer;