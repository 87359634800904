import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchBar.scss"

export default function SearchBar() {
  return (
    <div className="SearchBar-Container">
      <div className="searchInputs">
        <input
          type="text"
          placeholder='Search Term'
        />
        <SearchIcon />
      </div>
    </div>
  );
}
