import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetail: {
    email: "",
    isEmailValidate: ""
  },
  otp: {
    otp: ""
  },

  flag: {
    isLoginPage: true,
    isPasswordCreated: false,
    isEmailValid: false,
    isEmailVerifyed: false,
    isEmailPassVerifyed: false,
    isOtpIsValid: false,
    isForgetPassword: false,
    isResetPassword: false,
    isCallFromForgetPass: false,

  },
  roleType:""
};

const LoginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    handleLoginPage: (state, action) => {
      state.flag.isLoginPage = false;
    },
    handleIsPasswordCreated: (state, action) => {
      state.flag.isPasswordCreated = true;
    },

    handleIsEmailValid: (state, action) => {
      state.flag.isEmailValid = true;

    },
    handleIsEmailVerifyed: (state, action) => {
      state.flag.isEmailVerifyed = true;

    },
    handleIsEmailPassVerifyed: (state, action) => {
      state.flag.isEmailPassVerifyed = true;

    },

    handleIsOtpIsValid: (state, action) => {
      state.flag.isOtpIsValid = true;

    },
    handleForgetPassword: (state, action) => {
      state.flag.isForgetPassword = true;

    },
    handleResetPassword: (state, action) => {
      state.flag.isResetPassword = true;

    },
    handleIsCallFromForgetPass: (state, action) => {
      state.flag.isCallFromForgetPass = true;

    },
    saveEmail: (state, action) => {
      state.userDetail.email = action.payload;
    },
    saveEmailValidate: (state, action) => {
      state.userDetail.isEmailValidate = action.payload;
    },
    saveOtp: (state, action) => {
      state.otp = action.payload
    },
    updateRole: (state, action) => {
      state.roleType = action.payload
    },
    reset: () => initialState
  },
});

export const { handleIsPasswordCreated, saveEmail, handleIsEmailValid, handleIsEmailPassVerifyed, handleLoginPage, handleIsOtpIsValid, handleIsEmailVerifyed, handleForgetPassword, handleResetPassword, handleIsCallFromForgetPass, saveOtp, saveEmailValidate,updateRole } = LoginSlice.actions;
export const address = (state) => {
  return initialState
};

export default LoginSlice.reducer;