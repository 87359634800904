import { Paper, CircularProgress, Box, Typography } from '@mui/material';
import './Preview.scss'


export default function Preview({ color, item }) {
  return (
    <Paper className='preview-paper'>
      <div style={{ position: 'relative', height: '70px', width: '70px', borderRadius: '50px' }}>
        <CircularProgress variant='determinate' value={100} style={{ color: '#F1F1F1', height: '70px', width: '70px', }} />
        <CircularProgress variant='determinate' value={item?.weightagePercentage} style={{ position: 'absolute', color: color, height: '70px', width: '70px', left: '0', strokeLinecap: 'round' }} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption">
            {item?.weightagePercentage}%
          </Typography>
        </Box>
      </div>
      <div className='preview-text-content'>
        <span style={{ fontWeight: 'bold' }}>{item?.rankingParameterName}</span>
        <span>({item?.rankingParameterType})</span>
        <span>Range: {item?.rankingParameterTotalRange}</span>
      </div>
    </Paper>
  )
}
