import React, { useEffect } from "react";
import { ReactComponent as ArrowStart } from "../assets/NewArrow.svg";
import { ReactComponent as ArrowEnd } from "../assets/ArrowEnd.svg";
import { useLocation, useParams } from "react-router-dom";
import "./BreadCrumb.scss";
import { routes } from "../routes/index";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import { useState } from "react";
// Routes, 
// BreadCrumb Img
import AccountDetailsIcon from "../assets/BreadCrumImg/User_box_light.svg";
import ContactMethodIcon from "../assets/BreadCrumImg/Map.svg";
import ServiceCovergaeIcon from "../assets/BreadCrumImg/Pin_alt_light.svg";
import AdditionalStartingLocationIcon from "../assets/BreadCrumImg/Gps_fixed_light.svg";
import SkillsIcon from "../assets/BreadCrumImg/darhboard.svg";
import ServiceTimeBandsIcon from "../assets/BreadCrumImg/Time_progress_light.svg";
import RateMangementIcon from "../assets/BreadCrumImg/Group.svg";

// BreadCrumb Img (Inactive)

import InActiveAccountDetailsIcon from "../assets/BreadCrumImg/Inactive/User_box_light (1).svg";
import InActiveContactMethodIcon from "../assets/BreadCrumImg/Inactive/Map.svg";
import InActiveServiceCovergaeIcon from "../assets/BreadCrumImg/Inactive/Pin_alt_light.svg";
import InActiveAdditionalStartingLocationIcon from "../assets/BreadCrumImg/Inactive/Gps_fixed_light.svg";
import InActiveSkillsIcon from "../assets/BreadCrumImg/Inactive/darhboard.svg";
import InActiveServiceTimeBandsIcon from "../assets/BreadCrumImg/Inactive/Time_progress_light.svg";
import InActiveRateMangementIcon from "../assets/BreadCrumImg/Inactive/Group.svg";

export default function BreadCrumb() {
  const getGlobalViewModeKey = useSelector((state) => {
    return state?.accountDetail?.globalViewModeKey

  })
  const getFromSessionStorage = (data) => {
    try {
      const item = sessionStorage.getItem(data);
      return item;
    } catch (error) {
      if (data == "servicerId") {
        const item = servicerIdFromRedux
        return item
      }
      if (data == "servicerType") {
        return servicerTypefromredux
      }
      if (data == "serviceLocationId") {
        return serviceLocationIdfromredux
      }
    }
  };

  const [isHovered, setIsHovered] = useState({});
  const [activeItem, setActiveItem] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const breadCrumbItems = [
    {
      key: 1,
      title: "Account Details",
      ActiveImg: AccountDetailsIcon,
      InActiveImg: InActiveAccountDetailsIcon,
    },

    {
      key: 2,
      title: "Contact Method",
      ActiveImg: ContactMethodIcon,
      InActiveImg: InActiveContactMethodIcon,
    },
    {
      key: 3,
      title: "Service Coverage",
      ActiveImg: ServiceCovergaeIcon,
      InActiveImg: InActiveServiceCovergaeIcon,
    },
    {
      key: 4,
      title: "Additional Starting Location",
      ActiveImg: AdditionalStartingLocationIcon,
      InActiveImg: InActiveAdditionalStartingLocationIcon,
    },
    {
      key: 5,
      title: "Skill Inclusion",
      ActiveImg: SkillsIcon,
      InActiveImg: InActiveSkillsIcon,
    },
    {
      key: 6,
      title: "Skill Exclusion",
      ActiveImg: SkillsIcon,
      InActiveImg: InActiveSkillsIcon,
    },
    {
      key: 7,
      title: "Service Time Bands",
      ActiveImg: ServiceTimeBandsIcon,
      InActiveImg: InActiveServiceTimeBandsIcon,
    },
    {
      key: 8,
      title: "Rate Management",
      ActiveImg: RateMangementIcon,
      InActiveImg: InActiveRateMangementIcon,
    },
  ];

  const [servicerType, setServicerType] = useState(getFromSessionStorage("servicerType"))
  const [serviceLocationId, setServiceLocationId] = useState(getFromSessionStorage("serviceLocationId"))

  //Written to show breadcrumb in these pages where rotes match with name
  const breadCrumbNavigation =
    [{ name: "Account Details" },
    { name: "Contact Method" },
    { name: "Service Coverage", servicerType: 3, servicerLocationId: [1, 2] },
    { name: "Additional Starting Location", servicerType: 3, servicerLocationId: [1, 2] },
    { name: "Skill Inclusion" }, { name: "Skill Exclusion" }, { name: "Service Time Bands" },
    { name: "Rate Management" },]

  const { id: servicerIdFromRedux } = useParams()

  const servicerTypefromredux = useSelector((state) => {
    return state?.accountDetail?.servicerType;
  });

  const serviceLocationIdfromredux = useSelector((state) => {
    return state?.accountDetail?.serviceLocationId;
  });

  const servicerId = getFromSessionStorage("servicerId")

  useEffect(() => {
    // Your logic to execute when the URL changes
    breadCrumbItems.map((item, index, breadCrumbItems) => {
      const targetString = item.title;
      const urlWithoutSpaces = location.pathname.replace(/\s+/g, ''); // Remove all spaces from the URL
      const targetStringWithoutSpaces = targetString.replace(/\s+/g, ''); // Remove all spaces from the target string

      if (urlWithoutSpaces.includes(targetStringWithoutSpaces)) {
        setActiveItem(item.key)

      }
    })

  }, [location]);


  useEffect(() => {
    setServicerType(getFromSessionStorage("servicerType"))
    setServiceLocationId(getFromSessionStorage("serviceLocationId"))
  })

  const pathnameParts = location.pathname.split("/");

  let lastElement = pathnameParts[pathnameParts.length - 1];

  if (!isNaN(lastElement)) {
    lastElement = pathnameParts[pathnameParts.length - 2];
  }

  const activeIndex = breadCrumbNavigation.findIndex(
    (item) => item.name.replace(/\s/g, "") === lastElement
  );

  if (activeIndex === -1) {
    return null;
  }

  const goto = (item) => {
    const servicerId = getFromSessionStorage("servicerId")

    let activeItem = item.title.replace(/\s/g, "");
    for (const route of routes) {
      let pathParts;

      if (route.path.includes(':')) {
        pathParts = route.path.split(':');
      } else {
        pathParts = [route.path];
      }

      if (pathParts.length === 2) {
        let url = pathParts[0];
        const urlParts = url.split("/");
        let lastElement = urlParts[urlParts.length - 2];

        if (lastElement === activeItem && servicerId) {
          url = pathParts[0] + servicerId
          if (servicerType == 3) {
            if (serviceLocationId === '') {

              navigate(url)
            }
            else {

              if (serviceLocationId === 1 || serviceLocationId === 2) {
                navigate(url)
              }
              else {
                if (pathParts[0] === '/NetworkManagement/Servicer/AccountDetails/'
                  || pathParts[0] === '/NetworkManagement/Servicer/ContactMethod/'
                  || pathParts[0] === '/NetworkManagement/Servicer/ServiceTimeBands/'
                  || pathParts[0] === '/NetworkManagement/Servicer/SkillInclusion/'
                  || pathParts[0] === '/NetworkManagement/Servicer/SkillExclusion/'
                  || pathParts[0] === '/NetworkManagement/RateManagement/'
                  || pathParts[0] === '/NetworkManagement/Servicer/ServiceCoverage/'
                  || pathParts[0] === '/NetworkManagement/Servicer/AdditionalStartingLocation/') {
                  navigate(url)
                }
              }
            }
          }
          else {
            if (pathParts[0] === '/NetworkManagement/Servicer/AccountDetails/'
              || pathParts[0] === '/NetworkManagement/Servicer/ContactMethod/'
              || pathParts[0] === '/NetworkManagement/Servicer/ServiceTimeBands/'
              || pathParts[0] === '/NetworkManagement/Servicer/SkillInclusion/'
              || pathParts[0] === '/NetworkManagement/Servicer/SkillExclusion/'
              || pathParts[0] === '/NetworkManagement/RateManagement/') {

              navigate(url)
            }
          }
        }
      }
      else {

        const urlParts = route.path.split("/");
        let lastElement = urlParts[urlParts.length - 1];
        if (lastElement === activeItem) {
          if (route.path === '/NetworkManagement/RateManagement' || route.path === "/NetworkManagement/AddNewServicer/AccountDetails" || route.path === "/NetworkManagement/AddNewServicer/ContactMethod" || route.path === "/NetworkManagement/AddNewServicer/ServiceCoverage" || route.path === "/NetworkManagement/AddNewServicer/AdditionalStartingLocation" || route.path === "/NetworkManagement/AddNewServicer/ServiceTimeBands") {
            navigate(route.path)
          }
        }

      }
    }
  };

  const handleMouseEnter = (itemkey) => {
    if (activeItem == null) setIsHovered({ [itemkey]: true });
  };

  const handleMouseLeave = (itemkey) => {
    if (activeItem == null) setIsHovered({ [itemkey]: false });
  };

  const handleClick = (itemKey, item) => {
    const servicerType = getFromSessionStorage("servicerType")

    const serviceLocationId = getFromSessionStorage("serviceLocationId")

    let activeItem = item.title.replace(/\s/g, "");
    let checkurl = activeItem.includes("AdditionalStartingLocation")
    let checkserviceurl = activeItem.includes("ServiceCoverage")

    // 
    if (servicerType == 2 || servicerType == 1) {
      if (!checkurl && !checkserviceurl) {
        setIsHovered({ [itemKey]: true });
        setActiveItem(itemKey);
        goto(item);
      }
    }
    else if (servicerType == 3) {
      if (!checkurl && !checkserviceurl) {
        setIsHovered({ [itemKey]: true });
        setActiveItem(itemKey);
        goto(item);
      }
      else {
        if (serviceLocationId == 1 || serviceLocationId == 2 || serviceLocationId.length == 0) {
          setIsHovered({ [itemKey]: true });
          setActiveItem(itemKey);
          goto(item);
        }
      }
    }
    else if (!servicerType && !serviceLocationId) {
      goto(item)
    }

  };

  const dashedLine = (length, spacing) => {
    const dash = '_';
    const space = ' ';
    let line = '';
    for (let i = 0; i < length; i++) {
      line += i % 2 === 0 ? dash : space;
    }
    return line;
  };

  return (
    <div className="breadcrumb" style={{ width: "100%", backgroundColor: "#fff" }}>
      <div className="breadcrumb-btn" >
        {breadCrumbItems.map((item, index) => (
          <React.Fragment key={item.key}>
            <Button
              disableFocusRipple
              disableRipple
              // style={{minWidth:"133px"}}
              sx={{
                backgroundColor: "white",
                borderRadius: "7px",
                color: "#363F46",
                height: "60px",
                width: "120px",
                // mariginBottom: "10px",
                marginBottom: "20px",
                marginTop: "20px",


                boxShadow: "0px 6px 18px 0px rgba(0, 0, 0, 0.09)",
                transition: "background-color 0.3s, color 0.3s, img 0.3s",

                // border:"1px solid blue",

                "&:hover": {
                  backgroundColor: "#1E384B",
                  color: "white",
                  border: "none",
                },
                ...(activeItem === item.key
                  ? {
                    backgroundColor: "#1E384B",
                    color: "white",
                  }
                  : {}),
              }}
              onMouseEnter={() => handleMouseEnter(item.key)}
              onMouseLeave={() => handleMouseLeave(item.key)}
              onClick={() => handleClick(item.key, item)}

              disabled={index === 0 ? false : getGlobalViewModeKey == 'post' ? true :

                servicerType && (serviceLocationId || serviceLocationId == "") &&
                ((servicerType == 2 || servicerType == 1) &&
                  (item.title.replace(/\s/g, "").includes("AdditionalStartingLocation") ||
                    item.title.replace(/\s/g, "").includes("ServiceCoverage"))) ||
                ((servicerType == 3) && (serviceLocationId == 3 || serviceLocationId == 4 || serviceLocationId == 5) &&
                  (item.title.replace(/\s/g, "").includes("AdditionalStartingLocation") ||
                    item.title.replace(/\s/g, "").includes("ServiceCoverage")))


              }
            >
              <div className="breadcrumb-btn1">
                <img
                  // src={isHovered[item.key] ? item.InActiveImg : item.ActiveImg}
                  src={item.ActiveImg}

                  width="25"
                  height="30"
                />
                <div
                  style={{ color: "inherit", transition: "color " }}
                  className="breadcrumb-btn1Text"
                >
                  {item.title}
                </div>
              </div>
            </Button>
            {index !== breadCrumbItems.length - 1 ? (
              <div className="doted" style={{ fontSize: "10px", color: "#AEB5BC", whiteSpace: "nowrap", flexGrow: "1" }}></div>
            ) : (
              ""
            )}
          </React.Fragment>
        ))}

      </div>
    </div>
  );
}
