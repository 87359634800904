// import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { configureStore } from "@reduxjs/toolkit";
import addressReducer from "../pages/ContactMethod/contactMethodsSlice";
import accountDetailReducer from "../pages/AccountDetails/accountDetailsSlice";
import serviceCoverageReducer from "../pages/ServiceCoverage/serviceCoverageSlice"
import skillExclusionReducer from "../pages/Skills/Skill-Exclusion/SkillExclusionSlice"
import LoginReducer from "../pages/Login/LoginSlice"
import ContactMethodContainerSlice from "../pages/ServicerPortal/CompanyProfile/AccountDetails/Dialogs/ContactMethod/ContactMethodContainerSlice";

const store = configureStore({
    reducer: {
        address: addressReducer,
        accountDetail: accountDetailReducer,
        serviceCoverage: serviceCoverageReducer,
        skillExclusion: skillExclusionReducer,
        Login: LoginReducer,
        servicerPortalContact: ContactMethodContainerSlice
    },
});

export default store;