import { useState, useRef, useEffect } from "react";
import "./NavItem.scss";
import { Link } from "react-router-dom";

import SubNav from "./SubNav";
import { changeGlobalViewMode } from "../../pages/AccountDetails/accountDetailsSlice"
import { useDispatch, useSelector } from "react-redux";

const NavItem = ({ item }) => {


  const [isActive, setIsActive] = useState(false);
  const [activeImg, setHoveredImg] = useState(false)

  const getGlobalViewModeKey = useSelector((state) => {
    return state?.accountDetail?.globalViewModeKey

  })
  const dispatch = useDispatch()

  const navItemRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navItemRef.current && !navItemRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleLinkClick = (event) => {
    if (event.title === 'Add New Servicer') {
      dispatch(changeGlobalViewMode('post'))
    }
    else {
      dispatch(changeGlobalViewMode('notPost'))

    }
    if (event.title === 'Add New Servicer' || event.title === "Account Details" || event.title === "Contact Method" || event.title === "Service Coverage" || event.title === "Additional Starting Location" || event.title === "Skill Inclusion" || event.title === "Skill Exclusion" || event.title === "Service Time Bands" || event.title === "Rate Managements") {
      // sessionStorage.clear()
    }
    setIsActive(false)
  };
  const handleItemClick = (event) => {
    // if (event.title === 'Network Management') {
    //   dispatch(changeGlobalViewMode('notPost'))
    // }

    setIsActive(!isActive);

  };
  return (
    <>
      <li ref={navItemRef} className={`nav-item ${isActive ? "active" : ""}`} onMouseEnter={() => { setHoveredImg(true) }} onMouseLeave={() => { setHoveredImg(false) }}>
        {item?.subMenu ?
          (
            <div className={`sub-element`} style={{ zIndex: "10000" }} onClick={() => handleItemClick(item)}>{item?.title}{Array.isArray(item?.icon) === true ? (activeImg ? item?.icon[1] : item?.icon[0]) : (item?.icon)}</div>
          ) :
          (
            <Link to={item?.path} style={{ zIndex: "10000" }} className="sub-element" onClick={() => handleLinkClick(item)}>
              <Link to={item?.path} style={{ zIndex: "10000" }} className={`${activeImg ? "link1" : "link"}`}>{item?.title}</Link>{Array.isArray(item?.icon) === true ? (activeImg ? item?.icon[1] : item?.icon[0]) : (item?.icon)}</Link>
          )}


        {isActive && item.subMenu && <div style={{ zIndex: "10000" }} className="subMenu-container"><SubNav items={item?.subMenu} /></div>}
      </li>
    </>
  )
};

export default NavItem;
