import styled from "@emotion/styled";
import { InputLabel, Select, TextField, TableBody, TableHead, Dialog } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { PhoneInput } from "react-international-phone";
import { TextareaAutosize } from "@mui/base";
import { DataGrid } from "@mui/x-data-grid";


//{******************************[Select Component]******************************************}
export const StyleSelect = styled(Select)(
  ({ theme, formik, viewMode, condition, value }) => ({
    backgroundColor: "#F9F9F9",

    "& .MuiOutlinedInput-notchedOutline": {
      border: value || condition ? "1px solid #D6D3D3" : "1px solid #F9F9F9",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #02A0FC",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #02A0FC ",
    },
    "& .MuiInputLabel-root": {
      color: "#AEB5BC",
    },
  })
);

//{******************************[InputLabel Component]******************************************}
export const StyledInputLabel = styled(InputLabel)(
  ({ theme, formik, viewMode, value }) => ({
    color: "#AEB5BC",
  })
);

//{******************************[TextField Component]******************************************}
export const StyledTextField = styled(TextField)(
  ({ theme, formik, viewMode, value }) => ({
    backgroundColor: "#F9F9F9",
    border: "1px solid #F9F9F9",
    borderRadius: "7px",


    "& .MuiOutlinedInput-notchedOutline": {
      border: value ? "1px solid #D6D3D3" : "1px solid #F9F9F9",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #02A0FC ",
    },
    "& .MuiInputLabel-root": {
      color: "#AEB5BC",
    },
    "& label": {
      color: "#AEB5BC"
    },
  })
);

//{******************************[PhoneInput Component]******************************************}
export const StyledPhoneInput = styled(PhoneInput)(() => ({
  width: "100%",
  "input.react-international-phone-input ": {
    width: "100%",
  },

  "& .react-international-phone-input": {
    border: "0.5px solid #FFF",
    backgroundColor: "#F9F9F9",
    height: "2.7rem",
  },
  "&.react-international-phone-input-container .react-international-phone-country-selector-button":
  {
    border: "0.5px solid #FFF",
    backgroundColor: "#F9F9F9",

    height: "2.7rem",
  },
}));

//{******************************[MultiSelect Component]******************************************}

export const MultiSelectStyle = styled(Select)(
  ({ theme, formik, viewMode, condition, value }) => ({
    backgroundColor: "#F9F9F9 !important",

    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #02A0FC !important",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #02A0FC !important",
    },
    "& .MuiInputLabel-root": {
      color: "#AEB5BC",
    },

  })
);

//{******************************[TableHead Component]******************************************}
export const StyledTableHead = styled(TableHead)(() => ({
  // backgroundColor: "#F2F4FF",
  // borderRadius: "20px",
  "&.MuiTableHead-root ": {
    backgroundColor: "#F2F4FF",
    borderRadius: "20px"
  },
  "& .MuiTableCell-root": {
    color: "#233849",
    borderBottom: "none",
  },
  "&.MuiTableBody-root": {
    backgroundColor: "#FFFFFF"
  }
}));


//{******************************[TableBody Component]******************************************}
export const StyledTableBody = styled(TableBody)(() => ({
  "&.MuiTableBody-root": {
    backgroundColor: "#FFF",

  }

}))
//{******************************[DatePicker Component]******************************************}

export const StyledDatePicker = styled(DatePicker)(() => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#F9F9F9",
    borderRadius: "5px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #F9F9F9",
  },
}));

//{******************************[TextareaAutosize Component]******************************************}

export const StyledTextareaAutosize = styled(TextareaAutosize)(
  ({ theme, formik, viewMode, value, isError = "" }) => ({
    backgroundColor: "#F9F9F9",
    border: isError ? "2px solid red" : "2px solid #F9F9F9!important",
    borderRadius: "7px",
    "&:hover": {
      border: "2px solid #02A0FC!important",
    },



    // '& .MuiInputBase-root:focus': {
    //   border: ' 2px solid #02A0FC',
    // },
    // "& .MuiInputLabel-root": {
    //   color: "#AEB5BC",
    // },
  })
);

//{******************************[Scrollbar Component]******************************************}

export const StyledScrollbar = {
  "&::-webkit-scrollbar": {
    width: "8px", // Set the width of the scrollbar
    borderRadius: "5px" /* Add rounded corners */,
    backgroundColor: "#f0f0f0" /* Set the background color of the track */,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888", // Set the color of the scrollbar thumb
    borderRadius: "5px", // Set the border radius of the thumb
  },
  "&::-webkit-scrollbar-horizontal": {
    // height: "5px",
    // width: "8px" // Set the height of the scrollbar
  },
  "&::-webkit-scrollbar-vertical": {
    // width: "8px", // Set the width of the scrollbar
  },
};
export const StyledScrollbarHorrizontal = {
  "&::-webkit-scrollbar": {
    width: "10px",
    height: "8px", // Set the width of the scrollbar
    borderRadius: "5px" /* Add rounded corners */,
    backgroundColor: "#f0f0f0" /* Set the background color of the track */,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888", // Set the color of the scrollbar thumb
    borderRadius: "5px", // Set the border radius of the thumb
  },

};

//{******************************[Dailog Component]******************************************}

export const StyledDailog = styled(Dialog)(() => ({
  "& .MuiPaper-root-MuiDialog-paper": {
    borderRadius: "20px!important"
  }
}));


export const StyledTextFieldDialog = styled(TextField)(
  ({ theme, formik, viewMode, value }) => ({
    backgroundColor: "#F9F9F9",

    backgroundColor: !value ? "#F9F9F9" : '#FFF',
    border: "1px solid #F9F9F9",
    borderRadius: "7px",


    "& .MuiOutlinedInput-notchedOutline": {
      border: value ? "1px solid #D6D3D3" : "1px solid #F9F9F9",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #02A0FC ",
    },
    "& .MuiInputLabel-root": {
      color: "#AEB5BC",
    },

    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFF"
    }
  })
);

export const StyledSelectDialog = styled(Select)(
  ({ theme, formik, viewMode, condition, value }) => ({
    backgroundColor: "#F9F9F9",

    backgroundColor: !value ? "#F9F9F9" : '#FFF',


    "& .MuiOutlinedInput-notchedOutline": {
      border: value || condition ? "1px solid #D6D3D3" : "1px solid #F9F9F9",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #02A0FC",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #02A0FC ",
    },
    "& .MuiInputLabel-root": {
      color: "#AEB5BC",
    },
  })
);

export const StyledDataTable = styled(DataGrid)(() => ({
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: '#F2F4FF'
  },
  // "& .MuiDataGrid-footerContainer":{
  //   display: 'none !important'
  // }
}))