import { Button, Grid, Typography } from "@mui/material"
import ServiceAvengerIcon from "../../assets/SeviceAvengerIcon.svg"
import "./ServicerNavBar.scss"
import { menuItems } from "../../components/ServicerNavBar/ServicerNavBarMenuItems"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Image } from "@mui/icons-material"

const ServicerNavBar = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const [clickedButton, setClickedButton] = useState(location.state?.activeIndex);

  useEffect(() => {
    menuItems.map((item) => {
      if (item.path == location.pathname) {
        setClickedButton(item.key)
      }
    })
  }, [location])

  const handleOnClick = (key, path) => {
    setClickedButton(key);
    if (path) {
      navigate(path)
    }
  }

  return (
    <>
      <Grid xs={12} className="main-servicerNavBar" style={{ position: "relative", width: '100%', boxSizing: 'border-box', height: "100%" }} >

        <Grid xs={2} container className="main-contianer-servicerNavBar" style={{ position: "fixed", top: "0", left: "0", justifyContent: "start", alignItems: "start", }}>
          <Grid xs={12} className="main-container-avatar" style={{ display: "flex", justifyContent: "center" }}>
            <div className="image-container" >
              <img src={ServiceAvengerIcon} alt="icon" className="serviceAvenger-icon-image" height="90px" width="90px" />
            </div>

          </Grid>
          {
            menuItems.map((item, key) => (
              <Grid xs={12} className="servicer-name" item alignSelf="center" key={item.key} >
                <Button className="servicer-name-button" variant={clickedButton && clickedButton === item.key ? "contained" : ""}
                  disableElevation
                  disableFocusRipple
                  disableTouchRipple
                  sx={{
                      // border:"1px solid red",
                    width: "12.5vw",
                    wordBreak: "break-word",
                    "& .MuiTypography-root ": {
                      // backgroundColor: "#F3F6FD",
                      fontSize: "13px",

                    },
                    "&.MuiButton-root": {
                      display: "flex",
                      justifyContent: "start",
                      fontSize: "300px",
                    },
                    '@media  (max-width:767px)': {
                      width: "16px",
                      fontSize: "14px",
                      padding: "4px 12px",
                      // marginLeft:"1rem",
                      // marginRight:"1rem",
                      // border:"1px solid red" 
                    },
                    '@media (max-width:1023)': {
                      fontSize: "9.7px",
                      padding: "0 2px",
                      border:"1px solid red"
                    },
                    '@media (min-width: 1024px) and (max-width: 1282px)': {
                      fontSize: "14px",
                      padding: "5px 5px",
                      // border:"1px solid red"
                    },
                   
                  }}
                  onClick={() => handleOnClick(item.key, item?.path)}>{clickedButton && clickedButton === item.key ? <img src={item.Icon[1]} alt="icon" /> : <img src={item.Icon[0]} alt="icon" />} <span className="servicer-navbar-title" style={{ marginLeft: "5px" }}></span><Typography className="servicer-navbar-title">{item.title}</Typography></Button>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </>
  )
}
export default ServicerNavBar