import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    servicerId: '',
    countryId: '',
    currencySymbol: '',
    distanceUnit: '',
    serviceLocationId: '',
    servicerType: '',
    regionCode: '',
    globalViewModeKey: null
};

const accountDetailsSlice = createSlice({
    name: "accountDetails",
    initialState,
    reducers: {
        saveServicer: (state, action) => {
            state.servicerId = action.payload.servicerId
            state.countryId = action.payload.countryId
            // state.serviceLocationId = action.payload.serviceLocationId[0]
        },
        saveDistanceDetails: (state, action) => {
            state.currencySymbol = action.payload.currencySymbol
            state.distanceUnit = action.payload.distanceUnit
        },
        saveServiceLocationId: (state, action) => {
            const id = action.payload.serviceLocation.length === 1 ? action.payload.serviceLocation[0]?.id : ''
            state.serviceLocationId = id
            state.servicerType = action.payload.servicerType?.id
            state.regionCode = action.payload?.regionCode
        },
        changeGlobalViewMode: (state, action) => {
            state.globalViewModeKey = action.payload
        }
    },
});

export const { saveServicer, saveDistanceDetails, saveServiceLocationId, changeGlobalViewMode } = accountDetailsSlice.actions;

export default accountDetailsSlice.reducer;