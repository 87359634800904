import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotFound from '../pages/NotFound';
import AccessDenied from '../pages/AccessDenied/AccessDenied';
import Login from '../pages/Login/Login';


const PrivateRoute = ({ roleType, adminComponent = <NotFound />, normalComponent = <NotFound />, acessdenied = <NotFound />, notFound = "" }) => {

  const navigate = useNavigate();
  // const [userRole, setUserRole] = useState(localStorage.getItem('roleType'));
  const [userRole, setUserRole] = useState(localStorage.getItem('organisationType'));

  const token = localStorage.getItem("loginToken")

  if (!token) {
    return <Login />
  }
  if (notFound) {
    return notFound
  }
  if (userRole == "opw") {
    return adminComponent;
  }
  else if (userRole == "servicer") {
    return normalComponent;
  }
  else {
    return acessdenied
  }
};

export default PrivateRoute;