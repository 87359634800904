import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';


const Rout = ({ arr }) => {
  let path = window.location.pathname
  return (
    <>
      <div style={{ marginTop: "1rem" }}>
        {arr &&
          arr?.map((item, index) => (
            <>
              <React.Fragment key={index}>
                {index > 0 && <span style={{ color: '#BDBDBD', fontSize: "13px" }}> {'>'} </span>}
                {item.isActive ? (
                  <Link
                    to={item.isId ? path : item.route}
                    style={{
                      color:
                        path === item.route
                          ? "#02A0FC"
                          : item.isId &&
                            path.slice(0, `${window.location.pathname}`.lastIndexOf("/")) ===
                            `${item.route}`.slice(0, `${item.route}`.lastIndexOf("/"))
                            ? "#02A0FC"
                            : "#BDBDBD",
                      marginRight: '5px', textDecoration: 'none', fontSize: "13px",

                    }}
                  >
                    {item.name}
                  </Link>
                ) : (
                  <span style={{ color: '#BDBDBD', marginRight: '5px', fontSize: "13px" }}>{item.name}</span>
                )}
              </React.Fragment >
            </>
          ))
        }
      </div >
    </>
  );
};
export default Rout;











