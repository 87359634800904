import * as Yup from 'yup';

const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,63})$/;

const SetPasswordSchema = Yup.object().shape({

  newPassword: Yup.string()
    .required('Required')
    .min(8, 'Password must be at least 8 characters')
    .max(30, 'Password must be at most 30 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
    ),
  ConfirmPassword: Yup.string()
    .required('Required')
    .min(8, 'Password must be at least 8 characters')
    .max(30, 'Password must be at most 30 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
    )
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'), // ConfirmPassword must match newPassword
});

export default SetPasswordSchema;
