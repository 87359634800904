import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, plugins } from 'chart.js/auto'
import { Box } from '@mui/material';


export default function DoughnutChart({ chartData }) {

  const weightage = chartData?.filter(item => item.isActive).map(item => item.weightagePercentage) || []
  const label = chartData?.filter(item => item.isActive).map(item => item.rankingParameterName) || []
  const points = chartData?.filter(item => item.isActive).map(item => item.rankingParameterTotalRange) || []
  const color = chartData?.filter(item => item.isActive).map(item => item.activeColor) || []
  const dataDisplay = {
    datasets: [{
      data: weightage,
      // backgroundColor: [
      //   "#8395a7",
      //   "#FEA47F",
      //   "#34495e",
      //   "#74b9ff",
      //   "#a29bfe",
      //   "#ff7675",
      //   "#ffeaa7",
      //   "#ffbe76",
      //   "#c7ecee",
      //   "#994D1C",
      //   "#cf6a87",
      //   "#574b90",
      //   "#BDC581",
      //   "#25CCF7",
      //   "#B33771",
      //   "#227093",
      //   "#778ca3",
      //   "#cd6133",
      //   "#c7ecee",
      //   "#0fb9b1"
      // ]
      backgroundColor: color
      ,
      rankingName: label,
      points: points,
      borderWidth: 8,
      hoverBorderColor: "#F4F4F4",
      borderColor: "#F4F4F4"
    }]
  }
  // }
  const options = {
    cutout: '55%',
    radius: '90%',
    plugins: {
      tooltip: {
        displayColors: false,
        padding: 10,
        backgroundColor: '#FFF',
        bodyColor: '#000',
        borderColor: '#000',
        borderWidth: 1,
        callbacks: {
          label: function (context) {
            return `${context?.dataset?.rankingName[context?.dataIndex]}, Weightage:${context?.parsed}%, Maximum Points:${context?.dataset?.points[context?.dataIndex]}`
          }
        },
      }
    }
  }
  return (
    // <Box sx={{width:'500px',height:'500px'}}>
    <>
      {
        chartData?.length > 0 ? <Doughnut data={dataDisplay} options={options} style={{ zIndex: 1 }} /> : null
      }
    </>
    // </Box>
  )
}
