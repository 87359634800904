import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../axios/axios";
import { useParams } from "react-router-dom";

const ServiceName = () => {
  const { id } = useParams()

  const [servicerName, setServicerName] = useState("")
  const [servicerAccountId, setServicerAccountId] = useState("")

  const getAccountDetails = async () => {
    const response = await getRequest(`/api/getServicerAccount?servicerId=${id}`, "sdProvider")
    if (response?.data?.isSuccess) {
      setServicerName(response?.data?.data?.accountData.account.businessName);
      setServicerAccountId(response?.data?.data?.accountData.account.accountNumber);
      window.localStorage.setItem("isdCode", response?.data?.data?.isdCode)
      window.localStorage.setItem("phoneRegionCode", response?.data?.data?.phoneRegionCode)
    }
  }

  useEffect(() => {
    getAccountDetails()
  }, [id])

  return (
    <>
      <Typography sx={{ color: "#1E384B", fontSize: "14px" }}>
        {/* {servicerName} {"("}{servicerAccountId}{")"} */}
        {servicerName ? `${servicerName} ( ${servicerAccountId})` : ""}
      </Typography>
    </>
  )
}
export default ServiceName;






