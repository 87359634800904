import { useState, useEffect } from 'react'
import { getRequest } from '../../axios/axios'
import Preview from './Preview'
import DoughnutChart from '../../components/Doughnut/Doughnut'
import './DataDisplay.scss'
import { Paper, Button } from '@mui/material'
import EditICon from "../../assets/material-symbols_edit-outline.svg";
import { useParams, useNavigate } from "react-router-dom";
import Rout from '../../utils/Rout'
import { DispalyRanking } from '../../utils/Head'



export default function DataDisplay() {

    const [rankingData, setRankingData] = useState([])
    const [totalPercentage, setTotalPercentage] = useState(0)
    const navigate = useNavigate()
    const backgroundColor = [
        "#8395a7",
        "#FEA47F",
        "#34495e",
        "#74b9ff",
        "#a29bfe",
        "#ff7675",
        "#ffeaa7",
        "#ffbe76",
        "#c7ecee",
        "#994D1C",
        "#cf6a87",
        "#574b90",
        "#BDC581",
        "#25CCF7",
        "#B33771",
        "#227093",
        "#778ca3",
        "#cd6133",
        "#c7ecee",
        "#0fb9b1",
        "#D98880",
        "#EB984E",
        "#3498DB",
        "#BB8FCE",
        "#F5B7B1",
        "#524EB7",
        "#D2C2D1",
        "#EFCFE3",
        "#E76D89",
        "#A37C82",
        "#E9D460"
    ]

    const getRankingParameterList = async () => {
        const response = await getRequest("/api/getRankingParameterList", "sdRating")

        if (response && response.data?.isSuccess) {
            const newArray = response?.data?.data?.rankingParameterData?.map((item, index) => {
                return { ...item, activeColor: backgroundColor[index] }
            })
            if (newArray.length === 0) {
                navigate(`/RankingParameters`)
            }
            setRankingData(newArray)
            setTotalPercentage(response?.data?.data?.totalWeightagePercentage)
        }
    }

    useEffect(() => {
        getRankingParameterList()

    }, [])

    return (
        <>
            <Rout arr={DispalyRanking} />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
                <h4 className="Heading-text" style={{ textTransform: "Uppercase" }}> Set Ranking Parameters</h4>
                <Button
                    // className={btnstyle === true ? "btn_styling" : "btn_styling_selected"} 
                    size='small' type='submit' variant='outlined' sx={{
                        padding: "8px 10px", textTransform: "capitalize", border: "2px solid #02A0FC", "&:hover": {
                            border: "2px solid #02A0FC"
                        }
                    }} disableRipple

                    onClick={() => {
                        // btnstyle === true && setBtnstyle((prev) => !prev)
                        // setViewMode("put")
                        navigate(`/RankingParameters`)
                    }}

                >
                    <div
                        style={{ display: "flex", alignItems: "center" }}>
                        <img src={EditICon} alt='icon' />
                        Edit
                    </div>
                </Button>
            </div>
            <Paper elevation={3}>
                <div className='data-display'>
                    <div className='left-ranking'>
                        {/* {
                            rankingData?.length > 0 ? rankingData?.map((item, index) => {
                                if (index % 2 === 0 && item?.isActive) {
                                    return <Preview color={item?.activeColor} item={item} />
                                }
                            }) : null
                        } */}
                        { 
                            rankingData?.length > 0 && (
                                rankingData.sort((a, b) => a.rankingParameterName.localeCompare(b.rankingParameterName))
                                .map((item, index) => {
                                if (index % 2 === 0 && item?.isActive) {
                                    return <Preview color={item?.activeColor} item={item} key={index} />;
                                }
                                })
                            )
                        }
                    </div>
                    <div className='center-ranking'>
                        <DoughnutChart chartData={rankingData} />
                        <div className='doughnut-text'>
                            <span>{totalPercentage}%</span>
                            <span>Total Weightage</span>
                        </div>
                    </div>
                    <div className='right-ranking'>
                        {/* {
                            rankingData?.length > 0 ? rankingData?.map((item, index) => {
                                if (index % 2 !== 0 && item?.isActive) {
                                    return <Preview color={item?.activeColor} item={item} />
                                }
                            }) : null
                        } */}
                        { 
                            rankingData?.length > 0 && (
                                rankingData.sort((a, b) => a.rankingParameterName.localeCompare(b.rankingParameterName))
                                .map((item, index) => {
                                if (index % 2 !== 0 && item?.isActive) {
                                    return <Preview color={item?.activeColor} item={item} key={index} />;
                                }
                                })
                            )
                        }
                    </div>
                </div>
            </Paper>
        </>
    )
}
