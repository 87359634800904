import { useState, useEffect } from 'react'
import { Box, Button, Paper, Table, TableCell, TableContainer, TableRow, Typography, Grid, Stack, Pagination, List, ListItem, ListItemText } from "@mui/material";
import { StyledScrollbar, StyledTableBody, StyledTableHead, StyledScrollbarHorrizontal } from '../../../components/StyledComponent/StyledComponent';
import { getRequest, putRequest } from '../../../axios/axios';
import { Link } from "react-router-dom";
import './Jobs.scss';
import RejectReasonAuthDialog from "./RejectReasonAuthDialog";
import { toast } from 'react-toastify';

function Jobs() {
  const [tableData, setTableData] = useState([])
  const [onceClicked, setOnceClicked] = useState(false)
  const [offset, setOffset] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)
  const [totalPages, setTotalpages] = useState(0)
  const [page, setPage] = useState(1)
  const [openRejectAuthDialog, setOpenRejectAuthDialog] = useState(false)
  const [rejectAuthReasons, setRejectAuthReasons] = useState([])

  const [value, setValue] = useState("")
  const [orderId, setOrderId] = useState("")




  const getWindowSize = () => {
    const { innerWidth } = window;
    return innerWidth;
  }

  const getTableData = async () => {

    const response = await getRequest(`/api/orderList/servicer/my?&offset=${offset}&limit=10`, "sdDispatch")

    if (response && response.data.items) {
      setTotalEntries(response?.data?.total)
      setTableData(response.data.items)
    }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };


  })

  useEffect(() => {
    getTableData()
  }, [offset])

  const handleAcceptRejectOrder = async (value, orderId, comments, rejectReasonCode) => {
    setOnceClicked(true)
    let reqBody = {}
    if (value === "Accept") {
      reqBody = {
        "entityId": orderId,
        "status": value === "Accept" ? "In Progress" : "Done",
        "subStatus": value === "Accept" ? "Awaiting Appointment" : "Servicer Rejected"
      }
    }
    else {
      reqBody = {
        "entityId": orderId,
        "status": value === "Accept" ? "In Progress" : "Done",
        "subStatus": value === "Accept" ? "Awaiting Appointment" : "Servicer Rejected",
        "reasonNotes": comments,
        "reasonCode": rejectReasonCode

      }
    }

    const response = await putRequest(`/api/updateOrderStatus/${orderId}`,
      reqBody
      ,
      "sdDispatch"
    )

    if (response && response.data.isSuccess) {
      setOnceClicked(false)
      setOpenRejectAuthDialog(false)

      getTableData()
    }
    else {
      setOnceClicked(false)

    }
  }
  useEffect(() => {
    if (totalEntries % 10 === 0) {
      let calculatedTotalPages = totalEntries / 10;
      setTotalpages(calculatedTotalPages)
    } else {
      let calculatedTotalPages = Math.ceil(totalEntries / 10);
      setTotalpages(calculatedTotalPages)
    }

  }, [totalEntries])

  //handle page change in rank and dispatch list
  const handlePageChange = (event, value) => {
    setPage(value);
    const pageNumber = Number(value) - 1
    setOffset(pageNumber)
  }



  const handleOpenRejectAuthDialog = () => {
    setOpenRejectAuthDialog(true)

  }
  const handleCloseRejectAuthDialog = () => {
    setOpenRejectAuthDialog(false)
  }

  const handleReject = (value, orderId) => {
    setValue(value)
    setOrderId(orderId)
    handleOpenRejectAuthDialog()
  }
  //Reject Auth Reason//
  const getRejectAuthReaons = async () => {
    let obj = { "serviceNetworkID": "SP" }

    getRequest(`/api/getReasonList?providerType=OPW&reasonType=REJECT`, "sdIndustry")
      .then(response => {

        if (response.data.isSuccess) {

          setRejectAuthReasons(response?.data?.items)
        }
      })
      .catch(err => {
        toast.error(err)
      });
  }
  useEffect(() => {
    getRejectAuthReaons()
  }, [])



  return (
    <Grid xs={12} className='grid-container' container style={{ display: 'flex', justifyContent: "center", padding: "0rem 0rem" }} >

      <TableContainer TableContainer component={Paper} sx={{ ...StyledScrollbar, ...StyledScrollbarHorrizontal }
      } style={{ height: "45rem", overflowY: "scroll", marginBottom: "1rem", marginTop: "2rem" }}>
        <Table className='table-data-part' stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <StyledTableHead sx={{
            "&.MuiTableHead-root .MuiTableCell-root": {
              paddingTop: "10px", paddingBottom: "10px", backgroundColor: "#F2F4FF"

            }
          }}>
            <TableRow className='table-row'>
              <TableCell>Job Number</TableCell>
              <TableCell> <div style={{ width: "4.8rem" }}>Visit Date</div></TableCell>

              {/* <TableCell>Servic Order Created Date</TableCell> */}
              {/* <TableCell>Accepeted Date</TableCell> */}
              <TableCell className='table-cell'>Customer Name</TableCell>
              <TableCell className='table-cell'>Customer Address</TableCell>
              <TableCell className='table-cell'>Brand</TableCell>
              <TableCell className='table-cell'>Product</TableCell>
              <TableCell>Symptom Code</TableCell>
              <TableCell className='table-cell'>Description</TableCell>

              <TableCell align='center' className='table-cell'>Repeat Call</TableCell>
              <TableCell align='center' >
                Status

              </TableCell>
            </TableRow>
          </StyledTableHead>
          <StyledTableBody style={{ verticalAlign: "top" }}>
            {
              tableData && tableData?.map((item, index) =>
                <>
                  <TableRow className='table-row' key={index} >
                    <TableCell sx={{
                      "&.MuiTableCell-root ": {
                        padding: "10px 8px",
                      }
                    }}>{item?.serviceOrderId.charAt(0) === "X" ? item?.serviceOrderId :
                      <Link to={`/OrderDetails/${item.serviceOrderId}`}
                        style={{ color: " #02A0FC", textDecoration: "none" }}
                        onMouseEnter={(e) => e.target.style.textDecoration = "underline"}
                        onMouseLeave={(e) => e.target.style.textDecoration = "none"}
                      >
                        <Typography sx={{ width: "6rem" }}> {item.serviceOrderId}
                        </Typography>
                      </Link>
                      }</TableCell>
                    {/* <TableCell>{item?.serviceOrderCreateDate}</TableCell> */}
                    {/* <TableCell>{item?.updateDateTime ? item?.updateDateTime : "Pending"}</TableCell> */}
                    <TableCell sx={{
                      "&.MuiTableCell-root ": {
                        padding: "10px 5px",
                      },
                    }}>
                      <div>
                        {item?.appointmentDate + " "}
                      </div>

                      {item?.appointmentTimeSlot}

                    </TableCell>

                    <TableCell className='table-cell' sx={{
                      "&.MuiTableCell-root ": {
                        padding: "10px 5px",
                      }
                    }}>{item?.consumer.firstName + " "}{item?.consumer.lastName}</TableCell>
                    <TableCell className='table-cell' sx={{
                      "&.MuiTableCell-root ": {
                        padding: "10px 5px",
                      }
                    }}>{item?.consumer.address1 + ""}{item?.consumer.address2 + ", "}{item?.consumer.city + ","}{item?.consumer?.state + ","}{item?.consumer.postalCode}</TableCell>
                    <TableCell className='table-cell' sx={{
                      "&.MuiTableCell-root ": {
                        padding: "10px 5px",
                      }
                    }}>{item?.product?.brandName}</TableCell>
                    <TableCell className='table-cell' sx={{
                      "&.MuiTableCell-root ": {
                        padding: "10px 5px",
                      }
                    }}>{item?.product?.productName}</TableCell>
                    <TableCell sx={{
                      "&.MuiTableCell-root ": {
                        padding: "10px 5px",
                      }
                    }}>{item?.symptomCode}</TableCell>
                    <TableCell className='table-cell' sx={{
                      "&.MuiTableCell-root ": {
                        padding: "10px 5px",
                      }
                    }}>{item?.failureNote}</TableCell>

                    <TableCell className='table-cell' sx={{
                      "&.MuiTableCell-root ": {
                        padding: "10px 5px",
                      }
                    }} align='center'>{item?.repeatCall}</TableCell>
                    <TableCell sx={{
                      "&.MuiTableCell-root ": {
                        padding: "10px 5px",
                      }
                    }} align='center'>
                      {item.status === "In Progress" && item.subStatus === "Awaiting Servicer Acceptance" ?
                        <>
                          <div style={{ display: "flex", flexDirection: "column" }}>

                            <Button
                              disabled={onceClicked}
                              variant='contained' color='success' sx={{ width: "5rem", marginBottom: "15px" }} onClick={() => handleAcceptRejectOrder("Accept", item.entityId)}>Accept</Button>

                            <Button
                              disabled={onceClicked}

                              variant='contained' color='error' sx={{ width: "5rem" }}
                              // onClick={() => handleAcceptRejectOrder("Reject", item.entityId)}
                              onClick={() => handleReject("Reject", item.entityId)}

                            >Reject</Button>
                          </div>

                        </>
                        : <>

                          {/* <ListItemText primaryTypographyProps={{ width: "4rem" }} primary={`Status :  `}></ListItemText> */}
                          <div style={{ display: "flex", justifyContent: 'center' }}>

                            <Typography
                              sx={{
                                bgcolor: item.status === 'Open' ? "#E1F1FE!important" : item.status === 'In Progress' ? "#F6E6E2!important" : item.status === 'Done' ? "#DCF8FF!important" : "" || item.subStatus === 'Open' ? "#E1F1FE!important" : item.subStatus === 'In Progress' ? "#F6E6E2!important" : item.subStatus === 'Done' ? "#DCF8FF!important" : "",
                                color: item.status === 'Open' ? "#02A0FC" : item.status === 'In Progress' ? "#E56A54" : item.status === 'Done' ? "#009ABE" : "" || item.subStatus === 'Open' ? "#02A0FC" : item.subStatus === 'In Progress' ? "#E56A54" : item.subStatus === 'Done' ? "#009ABE" : "",

                                padding: "10px 10px",
                                borderRadius: "20px",
                                fontSize: "12px",
                                width: "85px",
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                              }}>
                              <div>
                                {item.status}

                              </div>
                              {item.subStatus}

                            </Typography>

                          </div>

                        </>


                        /* <ListItem>
                          <ListItemText primaryTypographyProps={{ width: "6.8rem" }} primary={`Sub-Status :  `}></ListItemText>
                          <ListItemText secondary={
                            <Typography
                              sx={{
                                bgcolor: item.subStatus === 'Open' ? "#E1F1FE!important" : item.subStatus === 'In Progress' ? "#F6E6E2!important" : item.subStatus === 'Done' ? "#DCF8FF!important" : "",
                                color: item.subStatus === 'Open' ? "#02A0FC" : item.subStatus === 'In Progress' ? "#E56A54" : item.subStatus === 'Done' ? "#009ABE" : "",
  
                                padding: "10px 10px",
                                borderRadius: "20px",
                                fontSize: "14px",
                                width: "78px",
                                textAlign: "center"
                              }}>
                              {item.subStatus}
                            </Typography>}>
  
                          </ListItemText>
  
                        // </ListItem> */
                        // </List>
                        // </>
                      }
                    </TableCell >
                  </TableRow >
                </>
              )
            }
          </StyledTableBody>
        </Table>
      </TableContainer >
      {
        openRejectAuthDialog && (
          <RejectReasonAuthDialog
            handleOpenRejectAuthDialog={handleOpenRejectAuthDialog}
            handleCloseRejectAuthDialog={handleCloseRejectAuthDialog}
            handleAcceptRejectOrder={handleAcceptRejectOrder}
            rejectAuthReasons={rejectAuthReasons}
            value={value}
            orderId={orderId}

          />
        )
      }
      <Grid container style={{ marginTop: "0.9rem", justifyContent: "flex-end" }}>
        <Stack spacing={2}>
          <Pagination count={totalPages} page={page} variant="outlined" shape="rounded" onChange={handlePageChange} />
        </Stack>
      </Grid>

    </Grid >
  )
}

export default Jobs