import * as yup from 'yup';


const textReg = /^[a-zA-Z 0-9: -]+$/

export const createSchema = (selectionDropdown) => yup.object().shape({

  rankingParameterName: yup.string().required('Ranking parameter name is required').matches(
    /^(?!.*\s{2,})\S(?:.*\S)?$/,
    "Leading white space is not allowed."
  ).matches(textReg, { message: "Invalid input" }).max(50, 'Maximum length is 50 characters'),

  rankingParameterTotalRange: yup.string().required('Maximum Points is Required').matches(
    /^(?!.*\s{2,})\S(?:.*\S)?$/,
    "Leading white space is not allowed."
  ).test(
    'custom-validation-total',
    'Invalid input',
    function (value) {
      
      if (this?.parent?.rankingParameterType === "Binary") {
        return true
      }

      const filteredObject = selectionDropdown?.reduce((acc, item) => {
        if (item.rankingTypeName !== "Binary") {
          acc[item.rankingTypeName] = item;
        }
        return acc;
      }, {});

      if (value.includes('.')) {
        return this.createError({ message: 'Decimal values are not allowed' });
      }
      const numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(value)) {
        return this.createError({ message: 'Invalid Input' });
      }

      const numericValue = parseInt(value);

      if (isNaN(numericValue)) {
        return this.createError({ message: 'Invalid input - Not a number' });
      }

      if (this?.parent?.rankingParameterType === "Higher The Better") {
        if (numericValue < filteredObject[this?.parent?.rankingParameterType].minValue) {
          return this.createError({ message: `Minimum value is ${filteredObject[this?.parent?.rankingParameterType].minValue}` });
        }

        if (numericValue > filteredObject[this?.parent?.rankingParameterType].maxValue) {
          return this.createError({ message: `Maximum value is ${filteredObject[this?.parent?.rankingParameterType].maxValue}` });
        }
      }

      if (this?.parent?.rankingParameterType === "Lower The Better") {
        if (numericValue < filteredObject[this?.parent?.rankingParameterType].minValue) {
          return this.createError({ message: `Minimum value is ${filteredObject[this?.parent?.rankingParameterType].minValue}` });
        }

        if (numericValue > filteredObject[this?.parent?.rankingParameterType].maxValue) {
          return this.createError({ message: `Maximum value is ${filteredObject[this?.parent?.rankingParameterType].maxValue}` });
        }
      }

      if (value.length > 1 && value.startsWith('0')) {
        return this.createError({ message: 'Leading zeroes are not allowed' });
      }

      return true; // Validation passed
    }
  ),

  rankingParameterType: yup.string().required('Ranking parameter type is required'),

  weightagePercentage: yup.string().required('Weightage percentage is Required').matches(
    /^(?!.*\s{2,})\S(?:.*\S)?$/,
    "Leading white space is not allowed."
  ).test(
    'custom-validation-percentage',
    'Invalid input',
    function (value) {

      if (value.includes('.')) {
        return this.createError({ message: 'Decimal values are not allowed' });
      }
      const numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(value)) {
        return this.createError({ message: 'Invalid Input' });
      }

      const numericValue = parseInt(value);

      if (isNaN(numericValue)) {
        return this.createError({ message: 'Invalid input - Not a number' });
      }

      if (numericValue < 1) {
        return this.createError({ message: 'Minimum value is 1' });
      }

      if (numericValue > 100) {
        return this.createError({ message: 'Maximum value is 100' });
      }

      if (value.length > 1 && value.startsWith('0')) {
        return this.createError({ message: 'Leading zeroes are not allowed' });
      }

      return true; // Validation passed
    }
  ),
})


export const createTableSchema = (selectionDropdown) => yup.object().shape({
  rankingParameterData: yup.array().of(yup.object().shape({

    rankingParameterName: yup.string().required('Ranking parameter name is required').matches(
      /^(?!.*\s{2,})\S(?:.*\S)?$/,
      "Leading white space is not allowed."
    ).matches(textReg, { message: "Invalid input" }).matches(/^[^",%,#,(,),$,*,!]+$/, "Special characters are not allowed.")
      .max(50, 'Maximum length is 50 characters'),

    rankingParameterTotalRange: yup.string().required('Maximum Points is Required').matches(
      /^(?!.*\s{2,})\S(?:.*\S)?$/,
      "Leading white space is not allowed."
    ).test(
      'custom-validation-total-range',
      'Invalid input',
      function (value) {
        
        if (this?.parent?.rankingParameterType === "Binary") {
          return true
        }

        const filteredObject = selectionDropdown?.reduce((acc, item) => {
          if (item.rankingTypeName !== "Binary") {
            acc[item.rankingTypeName] = item;
          }
          return acc;
        }, {});

        if (value.includes('.')) {
          return this.createError({ message: 'Decimal values are not allowed' });
        }
        const numericRegex = /^[0-9]+$/;

        if (!numericRegex.test(value)) {
          return this.createError({ message: 'Invalid Input' });
        }

        const numericValue = parseInt(value);

        if (isNaN(numericValue)) {
          return this.createError({ message: 'Invalid input - Not a number' });
        }

        if (this?.parent?.rankingParameterType === "Higher The Better") {
          if (numericValue < filteredObject[this?.parent?.rankingParameterType].minValue) {
            return this.createError({ message: `Minimum value is ${filteredObject[this?.parent?.rankingParameterType].minValue}` });
          }

          if (numericValue > filteredObject[this?.parent?.rankingParameterType].maxValue) {
            return this.createError({ message: `Maximum value is ${filteredObject[this?.parent?.rankingParameterType].maxValue}` });
          }
        }

        if (this?.parent?.rankingParameterType === "Lower The Better") {
          if (numericValue < filteredObject[this?.parent?.rankingParameterType].minValue) {
            return this.createError({ message: `Minimum value is ${filteredObject[this?.parent?.rankingParameterType].minValue}` });
          }

          if (numericValue > filteredObject[this?.parent?.rankingParameterType].maxValue) {
            return this.createError({ message: `Maximum value is ${filteredObject[this?.parent?.rankingParameterType].maxValue}` });
          }
        }

        if (value.length > 1 && value.startsWith('0')) {
          return this.createError({ message: 'Leading zeroes are not allowed' });
        }

        return true; // Validation passed
      }
    ),

    rankingParameterType: yup.string().required('Ranking parameter type is required'),

    weightagePercentage: yup.string().required('Weightage percentage is Required').matches(
      /^(?!.*\s{2,})\S(?:.*\S)?$/,
      "Leading white space is not allowed."
    ).test(
      'custom-validation-percentage-table',
      'Invalid input',
      function (value) {

        if (value.includes('.')) {
          return this.createError({ message: 'Decimal values are not allowed' });
        }
        const numericRegex = /^[0-9]+$/;

        if (!numericRegex.test(value)) {
          return this.createError({ message: 'Invalid Input' });
        }

        const numericValue = parseInt(value);

        if (isNaN(numericValue)) {
          return this.createError({ message: 'Invalid input - Not a number' });
        }

        if (numericValue < 1) {
          return this.createError({ message: 'Minimum value is 1' });
        }

        if (numericValue > 100) {
          return this.createError({ message: 'Maximum value is 100' });
        }

        if (value.length > 1 && value.startsWith('0')) {
          return this.createError({ message: 'Leading zeroes are not allowed' });
        }

        return true; // Validation passed
      }
    ),
  }))
})