import { useState, useEffect, useRef } from "react";
import axios from "axios";

const useFetch = (url,active="") => {
  let baseurl = process.env.REACT_APP_BASE_URL
  let activeUrl = JSON.parse(process.env.REACT_APP_BASE_URL_LIST)
  activeUrl.services.map((item)=>{
      if(item.code === active){
          baseurl = item.url
      }
  })
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const urlRef = useRef(url);
  useEffect(() => {
    if (!urlRef.current) return;

    const getDataFromApi = async () => {
      const logIntoken = localStorage.getItem("loginToken")

      const apiConfig = {
          headers: {
              'Authorization': `Bearer ${logIntoken}`
          }
      };
      try {
        setLoading(true);
        const initRes = await axios.get(
          `${baseurl}/api/${urlRef.current}`,apiConfig
        );
          
        setData(initRes.data.items);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };
    getDataFromApi();
  }, []);

  return { data, loading, error };
};

export default useFetch;
