import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    servicerId: '',
    currencySymbol: '',
    distanceUnit: '',
    latitude: '',
    longitude: ''
};

const serviceCoverageSlice = createSlice({
    name: "serviceCoverage",
    initialState,
    reducers: {
        saveServicer: (state, action) => {
            state.servicerId = action.payload.servicerId
            state.currencySymbol = action.payload.currencySymbol
            state.distanceUnit = action.payload.distanceUnit
            // state.countryId = action.payload.countryId
        },
        saveDistanceDetails: (state,action) =>{
            state.currencySymbol = action.payload.currencySymbol
            state.distanceUnit = action.payload.distanceUnit
        },
        saveCenter: (state,action)=>{
            if(!action.payload){
                state.latitude = ''
                state.longitude = ''
            }
            else{
                state.latitude = action.payload.latitude
                state.longitude = action.payload.longitude
            }

        },
    },
});

export const { saveServicer,saveDistanceDetails,saveCenter } = serviceCoverageSlice.actions;

export default serviceCoverageSlice.reducer;