import { useState, useEffect } from 'react';
import { menuItems } from './MenuItems'
import NavItem from './NavItem'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "./Navbar.scss"


export default function Navbar() {
  const [menuIcon, setMenuIcon] = useState(false)

  const openMenu = () => {
    setMenuIcon(!menuIcon)
  }

  return (
    <div className='Navbar' style={{}}>
      <div className='hamburger' onClick={openMenu}>{menuIcon ? <CloseIcon /> : <MenuIcon />}Menu</div>
      <ul className={`nav-list${menuIcon ? 'active' : ''}`}>
        {
          menuItems?.map((item) => <NavItem key={item?.title} item={item} />)
        }
      </ul>
      <ul className="nav-list-desk">
        {
          menuItems?.map((item) => <NavItem key={item?.title} item={item} />)
        }
      </ul>
    </div>
  )
}
