import { useState, useEffect } from 'react'
import { Box, Button, Paper, Table, TableCell, TableContainer, TableRow, Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from "@mui/material";
import { StyledScrollbar, StyledTableBody, StyledTableHead, StyledScrollbarHorrizontal } from "../../components/StyledComponent/StyledComponent";
import { getRequest, putRequest } from '../../axios/axios';
import { useParams, useNavigate } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import "./MyJob.scss"


export default function MyJob() {
    const { id } = useParams();

    const [tableData, setTableData] = useState([])

    const getWindowSize = () => {
        const { innerWidth } = window;
        return innerWidth;
    }

    const getTableData = async () => {
        const token = '2fbd315d-9a1c-4583-b188-7f354efd8b07';

        // Axios request configuration
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        const response = await getRequest(`/api/orderList/my?servicerID=${id}`, "sdDispatch", config)

        if (response && response.data.data) {
            setTableData(response.data.data)
        }
    }

    const [windowSize, setWindowSize] = useState(getWindowSize())

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize())
        }
        window.addEventListener('resize', handleWindowResize)
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };


    })

    useEffect(() => {
        getTableData()
    }, [])

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleAcceptRejectOrder = async (value, orderId) => {
        const token = '2fbd315d-9a1c-4583-b188-7f354efd8b07';

        // Axios request configuration
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        const response = await putRequest(`/api/updateOrderStatus/${orderId}`,
            {
                "serviceOrderId": orderId,
                "status": value === "Accept" ? "In Progress" : "Done",
                "subStatus": value === "Accept" ? "Awaiting Appointment" : "Servicer Rejected"
            },
            "sdDispatch", config
        )
        if (response && response.data.isSuccess) {
            getTableData()
        }
    }


    return (
        <div>
            <h2 style={{ marginBottom: "1.5rem", marginTop: "2rem", color: "#363F46" }}>My Jobs</h2>
            <TableContainer component={Paper} sx={{ ...StyledScrollbar, overflowX: windowSize > 768 ? "hidden" : null, ...StyledScrollbarHorrizontal }} style={{ height: "80vh", overflowY: "scroll", marginBottom: "2rem" }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <StyledTableHead sx={{
                        "&.MuiTableHead-root .MuiTableCell-root": {
                            paddingTop: "5px", paddingBottom: "5px", backgroundColor: "#F2F4FF"

                        }
                    }}>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Job Details</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </StyledTableHead>
                    <StyledTableBody style={{ verticalAlign: "top" }}>
                        {
                            tableData && tableData?.map((item, index) =>
                                <>
                                    <TableRow key={item.serviceOrderCreateDate} >
                                        <TableCell style={{ width: "10%", textAlign: "start", verticalAlign: "top" }}>{item.serviceOrderCreateDate}</TableCell>
                                        <TableCell style={{ verticalAlign: "top" }}>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: 'start', alignSelf: "flex-start" }}>
                                                {
                                                    item.status === "In Progress" && item.subStatus === "Awaiting Servicer Acceptance" ?
                                                        <h4 className='my-job-topHeading'>New Order Received</h4> :
                                                        item.subStatus === "Claim Denied" ?
                                                            <h4 className='my-job-topHeading'>{item.subStatus}</h4> :
                                                            <h4 className='my-job-topHeading'>OrderId: {item.serviceOrderId}</h4>
                                                }
                                                <Box sx={{
                                                    "& .MuiPaper-root-MuiAccordion-root": {
                                                        backgroundColor: "#FAFAFA"
                                                    }
                                                }}>
                                                    <Accordion sx={{
                                                        "&.MuiAccordion-root": {
                                                            backgroundColor: "#FAFAFA"
                                                        },
                                                    }} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                        >
                                                            <Grid container spacing={2}>
                                                                {
                                                                    item.status === "In Progress" && item.subStatus === "Awaiting Servicer Acceptance" || item.subStatus === "Claim Denied" ?
                                                                        <Grid container item spacing={3}>
                                                                            <Grid item sm={4}> <span className='my-job-subTitle-table'>Brand:</span> {item.product.brandName}</Grid>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Address:</span> {item.consumer.customerAddress || ""}</Grid>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Visit Date:</span> {item.appointmentDate || ""}</Grid>
                                                                        </Grid> :
                                                                        <Grid container item spacing={3}>
                                                                            {/* <Grid item sm={4}><span className='my-job-subTitle-table'>Order Id:</span> {item.serviceOrderId}</Grid> */}
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Brand:</span>{item.product.brandName || ""}</Grid>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Visit Date: </span>{item.appointmentDate || ""}</Grid>
                                                                        </Grid>
                                                                }
                                                            </Grid>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {
                                                                item.status === "In Progress" && item.subStatus === "Awaiting Servicer Acceptance" || item.subStatus === "Claim Denied" ?
                                                                    <Grid container spacing={2}>
                                                                        <Grid container item spacing={3}>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Product: </span>{item.product.productName || ""}</Grid>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Issue:</span> {item.symptomCode || ""}</Grid>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Time Slot:</span> {item.consumerRequestWindow || ""}</Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    :
                                                                    <Grid container spacing={2}>
                                                                        <Grid container item spacing={3}>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Consumer Name:</span> {item.consumer.customerName || ""}</Grid>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Address:</span> {item.consumer.customerAddress || ""}</Grid>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Time Slot: </span>{item.appointmentTimeSlot || ""}</Grid>
                                                                        </Grid>
                                                                        <Grid container item spacing={3}>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Company Name:</span> {item.serviceProvider || ""}</Grid>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Product:</span> {item.product.productName || ""}</Grid>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Issue:</span> {item.symptomCode || ""}</Grid>
                                                                        </Grid>
                                                                        <Grid container item spacing={3}>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Failure Date:</span> {item.failureDate || ""}</Grid>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Install Date:</span> {item.product.installDate || ""}</Grid>
                                                                            <Grid item sm={4}><span className='my-job-subTitle-table'>Service Type:</span> {item.serviceType || ""}</Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                            }
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Box>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "20%", verticalAlign: "top" }}>
                                            {item.status === "In Progress" && item.subStatus === "Awaiting Servicer Acceptance" ?
                                                <div className='accept-reject-btn-container'
                                                // style={{ display: "flex", gap: "10px" }}
                                                >
                                                    <div className='successbtn'>
                                                        <Button onClick={() => handleAcceptRejectOrder("Accept", item.serviceOrderId)}>
                                                            <i className='accept-btn'><DoneIcon sx={{ color: "#009ABE", fontSize: "15px" }} /></i>
                                                            Accept
                                                        </Button>
                                                    </div>
                                                    <div className='rejectbtn'><Button onClick={() => handleAcceptRejectOrder("Reject", item.serviceOrderId)}><i className='reject-btn'><CloseIcon sx={{ color: "#E56A54", fontSize: "15px" }} /></i> Reject</Button></div>
                                                </div>
                                                : item.status}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )
                        }
                    </StyledTableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
