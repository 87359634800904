import { Button, Dialog, DialogActions, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { toast } from 'react-toastify';
import { postRequest, putRequest } from '../../../axios/axios';
import { StyledTextareaAutosize } from '../../../components/StyledComponent/StyledComponent';

const RejectReasonAuthDialog = (
  {
    handleOpenRejectAuthDialog,
    handleCloseRejectAuthDialog,
    handleAcceptRejectOrder,
    orderId,
    value,
    rejectAuthReasons,


  }
) => {
  const [rejectReasonCode, setRejectReasonCode] = useState({ cancelCode: "", cancelReason: "" })
  const [isError, setIsError] = useState(false);
  const [comments, setComments] = useState("");




  const handleSaveStatus = () => {
    if (handleAcceptRejectOrder) {
      handleAcceptRejectOrder(value, orderId, comments, rejectReasonCode?.cancelCode)
    }

  }


  const handleAddcancelReason = (event) => {
    const selectedStatusName = event.target.value;

    const selectedStatus = rejectAuthReasons?.find(
      (status) =>
        selectedStatusName === status.reasonName
    );
    setRejectReasonCode({ cancelCode: selectedStatus.reasonCode, cancelReason: selectedStatusName })
  }


  return (
    <>
      <Dialog
        open={handleOpenRejectAuthDialog}
        aria-labelledby="customized-dialog-title"
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: '380px'
          }
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '2rem' }} >

        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseRejectAuthDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>


        <DialogActions sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '15px' }}>
          <Grid sx={{ textAlign: 'center' }}>
            <Typography style={{ fontWeight: '600', fontSize: '14px' }}>Please provide the reason to reject</Typography>
          </Grid>
          <Grid sx={{ margin: '10px 0px 20px 0px' }}>
            <FormControl size="small" sx={{ minWidth: 300 }}>
              <InputLabel id="demo-select-small-label" sx={{ fontSize: 'small' }}>Reject</InputLabel>
              <Select
                labelId="demo-select-small-label"
                value={rejectReasonCode.cancelReason}
                label="Reject"
                onChange={(event) => handleAddcancelReason(event)}
                sx={{ fontSize: "12px" }}
              >
                {
                  Array.isArray(rejectAuthReasons) && rejectAuthReasons?.map((item) => (
                    <MenuItem key={item.reasonCode} value={item.reasonName} sx={{ fontSize: 'small' }}>{item?.reasonName}</MenuItem>

                  ))
                }

              </Select>
            </FormControl>
          </Grid>
          <StyledTextareaAutosize aria-label="minimum height" minRows={2}
            style={{
              padding: "10px",
              fontFamily: "Poppins",
              borderWidth: "1px",
              borderRadius: "5px",
              borderStyle: "solid",
              height: "5vh",
              outlineColor: "#02A0FC",
              width: "80%"
            }}
            isError={isError}
            value={comments}
            placeholder="Enter Notes."
            onChange={(e) => {
              const note = e.target.value
              if (note?.length > 300) {
                setIsError(true)


              }
              else {
                setComments(note)
                setIsError(false)
              }

            }}

          />
          {isError && (<p style={{ color: "red", marginTop: 0 }}>{"Maximum Length Is 300 Characters"}</p>)}
          <Grid sx={{ margin: '1.5rem' }}>

            <Button sx={{ textTransform: "capitalize" }} size='large' variant='contained' color='secondary' onClick={handleCloseRejectAuthDialog} >No</Button>
            <Button style={{ textTransform: "capitalize", marginLeft: "10px" }} size='large' variant='contained' color='primary' disabled={rejectReasonCode.cancelReason === "" ? true : false} onClick={() => handleSaveStatus()} >Yes</Button>
          </Grid>

        </DialogActions>
      </Dialog>
    </>
  )
}

export default RejectReasonAuthDialog;