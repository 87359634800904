import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useMemo } from 'react';
import { toast } from 'react-toastify';

const Interceptor = () => {

  const controller = useMemo(() => new AbortController(), [])


  const navigate = useNavigate();

  const globalCancelTokenSource = useMemo(() => axios.CancelToken.source(), []);
  axios.interceptors.request.use(
    (config) => {
      config.signal = controller.signal;
      config.cancelToken = globalCancelTokenSource.token;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (axios.isCancel(error)) {
      } else {
        if (error.response && error.response.status === 401) {
          toast.error('Token Expired Please Login Again', {
            toastId: 'error401Toast',
            onClose: () => {
              toast.dismiss('error401Toast');

            },
          });
          window.localStorage.clear();
          window.sessionStorage.clear();
          // controller.abort()
          navigate('/');
        }
      }

      return Promise.reject(error);
    }
  );

  return (
    <>
    </>
  );
};
export default Interceptor

