import { Button, Grid, Paper } from '@mui/material'
import { useNavigate } from 'react-router'


export default function AccessDenied() {
    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate("/")
    }
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <Paper sx={{ height: "20em", width: "60%", margin: "auto", marginTop: "5em", padding: "2em" }}>
                <Grid className='text_align' >
                    <span style={{ fontSize: "20px", fontWeight: "600" }}>Access Denied</span>
                </Grid>
                {/* <Grid className='text_align' style={{marginTop:"3em"}}>
            <span style={{textTransform:"none"}}>
                Page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you
                think this is error contact support
            </span>
        </Grid> */}
                <Grid className='text_align' style={{ marginTop: "5em" }} elevation={4}>
                    <Button variant="contained" onClick={handleNavigate}>Take me back to Login</Button>
                </Grid>
            </Paper>
        </div>
    )
}
