import { Dashboard } from "@mui/icons-material";

import DashboardIconB from "../../assets/ServiceNavBarIcon/DashboardIocnB.svg"
import DashboardIconW from "../../assets/ServiceNavBarIcon/DashboardIocnW.svg"

import PaymentIocnB from "../../assets/ServiceNavBarIcon/PaymentIConB.svg"
import PaymentIocnW from "../../assets/ServiceNavBarIcon/PaymentIConW.svg"

import B1099Icon from "../../assets/ServiceNavBarIcon/1099IconB.svg"
import W1099Icon from "../../assets/ServiceNavBarIcon/1099IconW.svg"

import ClaimsIconB from "../../assets/ServiceNavBarIcon/ClaimsIconB.svg"
import ClaimsIconW from "../../assets/ServiceNavBarIcon/ClaimsIconW.svg"

import MyJobsIconB from "../../assets/ServiceNavBarIcon/MyJobsIconB.svg"
import MyJobsIconW from "../../assets/ServiceNavBarIcon/MyJobsIconW.svg"

import CompanyProfileB from "../../assets/ServiceNavBarIcon/CompanyProfileB.svg"
import CompanyProfileW from "../../assets/ServiceNavBarIcon/CompanyProfileW.svg"



export const menuItems = [
  // { key: "0", title: "Dashboard", isActive: "false", Icon: [DashboardIconB, DashboardIconW] },
  { key: "1", title: "Company Profile", isActive: "false", path: "/CompanyProfile", Icon: [CompanyProfileB, CompanyProfileW] },
  { key: "2", title: "Jobs", isActive: "false", path: "/Jobs", Icon: [MyJobsIconB, MyJobsIconW] },
  // { key: "3", title: "Claims", isActive: "false", Icon: [ClaimsIconB, ClaimsIconW] },
  // { key: "4", title: "Payments", isActive: "false", Icon: [PaymentIocnB, PaymentIocnW] },
  // { key: "5", title: "1099", isActive: "false", Icon: [B1099Icon, W1099Icon] }
]