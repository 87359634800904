import { LogLevel, Configuration } from "@azure/msal-browser";

let authority = process.env.REACT_APP_MICROSOFT_LOGIN_AUTHORITY
let clientId = process.env.REACT_APP_MICROSOFT_LOGIN_CLIENTID
let redirectUri = process.env.REACT_APP_MICROSOFT_LOGIN_REIRECTURI
let redirectUriClaims =  process.env.REACT_APP_MICROSOFT_LOGIN_REIRECTURICLAIMS


export const msalConfigClaims = {
  auth: {
    clientId: clientId,
    authority: authority,
    redirectUri: redirectUriClaims,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: authority,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['https://graph.microsoft.com/.default'],
}
