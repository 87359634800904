import React from 'react'
import SearchBar from '../SearchBar/SearchBar'
import UserDetails from '../UserDetails/UserDetails'
import Navbar from '../Navbar/Navbar'
import "./HeaderSidebar.scss"
import ServicerName from "../Header/ServicerName"
import { useParams } from 'react-router-dom'
import { Grid, Paper, Typography } from '@mui/material'
import { getRequest } from '../../axios/axios'


export default function HeaderSidebar() {
  const { id } = useParams()
  React.useEffect(() => {
    const storeIsdCode = async () => {
      const response = await getRequest(`/api/getServicerDetail/my`, "sdProvider")
      if (response?.data?.isSuccess) {
        window.localStorage.setItem("isdCode", response?.data?.data?.account?.isdCode)
        window.localStorage.setItem("phoneRegionCode", response?.data?.data?.dispatchMethod?.phoneRegionCode)
      }
    }
    storeIsdCode()
  }, [])

  return (
    <Grid xs={12} className='HeaderSidebar-container'
    // sx={{ position: "relative", width: "100%",border:'1px solid red',boxSizing:'border-box'}}
    >
      <Grid xs={12} component={Paper} elevation={3} className='HeaderSidebar' sx={{ width: "100%" }}>
        <div style={{ color: "#1E384B" }}> <Typography variant='h6' color='#1E384B' className='text-style'>{window.localStorage.getItem("organisationName") === 'undefined' ? "" : window.localStorage.getItem("organisationName")}</Typography></div>
        {/* <img src='/Logo.png' alt='logo' className='logo'></img> */}
        <div className='search-user'>
          {/* {id ? <ServicerName /> : null} */}
          <div className='search-bar'><SearchBar /></div>

          <UserDetails />
        </div>
      </Grid>
    </Grid>
  )
}
